<script>
import { defineComponent } from 'vue'
import DefaultDrawerHelp from '/~/components/drawer/views/settings/help/drawer-help.vue'
import { useLocalization } from '/~/composables/localization'

export default defineComponent({
  name: 'drawer-help',
  extends: DefaultDrawerHelp,
  setup() {
    const { translate } = useLocalization()

    return {
      translate,
    }
  },
})
</script>

<template>
  <base-aside-page title="Help">
    <div class="mb-2.5 font-bold text-eonx-neutral-600">Send us a message</div>
    <div class="mb-[30px] rounded-lg bg-light p-5">
      <validation-observer
        v-slot="{ handleSubmit }"
        ref="validationObserverRef"
        slim
      >
        <form @submit.prevent="handleSubmit(onSubmitForm)">
          <base-input
            v-model="form.name"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :disabled="submitting"
            :error="backendErrors.name"
            label="Name"
            required
            name="name"
          />
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required',
              name: 'Email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
          />
          <base-textarea
            v-model="form.message"
            :validation="{
              rules: 'required',
              name: 'Message',
            }"
            :disabled="submitting"
            :error="backendErrors.message"
            :maxlength="400"
            :rows="4"
            label="Message"
            required
            name="message"
          />
          <base-button
            type="submit"
            :disabled="submitting"
            full-width
            class="mt-10"
          >
            {{ submitting ? 'Sending...' : 'Send message' }}
          </base-button>
        </form>
      </validation-observer>
    </div>
    <div class="mb-2.5 font-bold text-eonx-neutral-600">Contact Us</div>
    <div class="mb-[30px] rounded-lg bg-light p-5">
      <div class="text-center">
        For lead, points or program {{ translate('common.enquiries') }}, please
        email
      </div>
      <a
        class="flex items-center justify-center font-bold no-underline"
        href="mailto:seekerrewards@seeker.auspost.com.au"
      >
        <base-icon
          class="mr-2.5 mt-[5px] text-primary"
          size="md"
          svg="australiapost/email"
        />
        seekerrewards@seeker.auspost.com.au
      </a>

      <div class="mt-[30px] text-center">
        For reward store or order {{ translate('common.enquiries') }}, please
        complete this form or call
      </div>
      <a
        class="flex items-center justify-center text-xl font-bold no-underline"
        :href="`tel:${contactPhone}`"
      >
        <base-icon
          class="mr-2.5 mt-[5px] text-primary"
          size="md"
          svg="menu/call"
        />
        {{ contactPhone }}
      </a>
      <div class="mt-[15px] text-center">
        Please note, calls and emails are managed 9.00am to 5.00pm (AEST),
        Monday to Friday.
      </div>
    </div>
  </base-aside-page>
</template>
